<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          v-if="allCountsLoaded"
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
          :key="componentKey"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/campaign_management/campaigns"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <AreYouSureDialog
      title="Soll die Kampagne gelöscht werden?"
      text="Wenn du die Kampagne tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  name: "RunningSpecialView",
  data() {
    return {
      pageTitle: "Aktive Kampagnen - mitfitMEMBER Special",
      pageDescription: "Kampagnen, die aktuell aktiv laufen",
      headers: [],
      items: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      fetchingData: false,
      dialog: false,
      stats: true,
      currentItemId: null,
      currentItemIndex: null,
      componentKey: 0,
      totalItems: 0,
      loadedItems: 0,
      allCountsLoaded: false,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  watch: {
    loadedItems() {
      if (this.loadedItems === this.totalItems) {
        this.allCountsLoaded = true;
        this.componentKey += 1;
      }
    },
  },

  methods: {
    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */

      let response = await this.deleteRequest(
        "campaigns/" + this.currentItemId
      ); // await data from mixin

      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 10000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 10000;
      }
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */

        this.currentItemId = value.itemId;
        this.currentItemIndex = value.index;

        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.$router.push({
          name: "campaign-management.campaigns.:id",
          params: { id: value.itemId },
        });
      }
    },

    async getLeadData(id) {
      const response = await this.getRequest(
        `/campaigns/${id}/statistics/leadsCount`
      );

      if (response.status === 200) {
        this.loadedItems += 1;
        return parseInt(response.data.data.leadsCount);
      }
      return 0;
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("campaigns?filter[status]=active"); // await data from mixin

      // prefix response.data.data.name with [TEST] when response.data.data.isTest === 1
      response.data.data.forEach((item) => {
        if (item.isTest === "1") {
          item.name = "[TEST] " + item.name;
        }
      });

      // if complete (response, data or false)

      if (response) {
        this.headers = [
          /* { text: "ID", value: "id" }, */
          /* { text: "Name", value: "title" }, */
          { text: "ID", value: "id" },
          { text: "Arbeitstitel", value: "name" },
          { text: "Kunde", value: "company.name" },
          { text: "Start", value: "dateStart" },
          { text: "Ende", value: "dateEnd" },
          { text: "Count", value: "leadsCount" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;

        // hasMitfitMember
        const that = this;

        let spliceArray = [];

        this.items.forEach(function (item, index) {
          if (
            item.hasMitfitMember === "0" ||
            item.hasMitfitMember === 0 ||
            !item.hasMitfitMember
          ) {
            spliceArray.push(index); // index merken zum späteren raus schmeißen - kein Members
          }
        });

        // entfernen der nicht member kampagnen
        for (let i = spliceArray.length - 1; i >= 0; i--) {
          this.items.splice(spliceArray[i], 1);
        }

        this.totalItems = this.items.length;

        this.items.forEach(async (item, index) => {
          const leadsCount = await this.getLeadData(item.id);
          that.items[index].leadsCount = leadsCount;
        });

        this.edit = true;
        this.trash = true;
        this.copy = true;
        this.preview = true;
        this.search = "";

        this.fetchingData = false;

        /* setTimeout(() => {
          this.componentKey += 1;
        }, 2000); */
      }
    },

    async createNew() {
      //alert("Neue Kampagne würde jetzt erstellt werden!");

      /* create new entry */
      let response = await this.postRequest("campaigns"); // await data from mixin
      //console.log(response);

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0
    },
  },

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    AreYouSureDialog,
  },

  mixins: [apiRequest],
};
</script>
